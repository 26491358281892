/* style.css */

@font-face {
	font-family: "Source Code Pro";
	font-style: normal;
	font-weight: 400;
	font-display: block;
	src: url("/fonts/HI_SiYsKILxRpg3hIP6sJ7fM7PqlPevWnsUnxg.woff2")
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
		U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.no-spinners::-webkit-inner-spin-button,
.no-spinners::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.no-spinners {
	-moz-appearance: textfield;
}

:root {
	--claimTokenWidth: 19rem;
	--maxWidth: 45rem;
	--brainWormDoctorColor1: #4a7a98;
	--brainWormDoctorColor2: #0e4d84;
}

body {
	font-family: "Source Code Pro", monospace;
	margin: 0;
	padding: 0;
	color: white;
	background-color: black;
	font-size: 1.1rem;
}

#responsive-iframe {
	width: 100%;
	height: 0;
	position: relative;
}
#responsive-iframe iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

iframe {
	border: none;
	max-width: var(--maxWidth);
}

p {
	max-width: var(--maxWidth);
}

ul {
	max-width: var(--maxWidth);
}
.larger-font {
	font-size: 1.2rem;
}

a {
	color: white;
	text-decoration: underline;
}

@keyframes flashingText {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.scrollbar-container::-webkit-scrollbar {
	width: 0.2em;
	background-color: #ffffff;
}

.scrollbar-container::-webkit-scrollbar-track {
	/* box-shadow: inset 0 0 6px rgba(14, 0, 255, 0.3); */
	/* -webkit-box-shadow: inset 0 0 6px rgba(14, 0, 255, 0.3); */
	/* background-color: #b4ffa9; */
}

.scrollbar-container::-webkit-scrollbar-thumb {
	background-color: #1c3764;
	outline: none; /*1px solid slategrey;*/
	border-radius: 0;
	/* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0); */
}

/* Brain Worm PVP Card Styles*/
.canvas-link {
	position: absolute;
	z-index: 150;
	filter: drop-shadow 0 0 1px #00baff;
}

.canvas-link img {
	width: 100%;
	height: auto;
	object-fit: contain;
	transition: filter 0.05s ease-in-out;
	filter: drop-shadow 0 0 1px #00baff;
}

.canvas-link:hover img {
	filter: brightness(140%) contrast(160%) drop-shadow(0 0 2px #00baff);
}

.brainWormsAlphaLogin {
	margin-top: 1rem;
	max-width: var(--maxWidth);
}
.server-message {
	font-weight: bold;
	margin-top: 10px;
	min-height: 1em;
	opacity: 0;
	animation: fadeIn 1s forwards;
}
.break-word {
	word-break: break-all;
}

form {
	max-width: 50%;
	/*center the element*/
	margin-left: auto;
	margin-right: auto;
}
@keyframes fadeIn {
	to {
		opacity: 1;
	}
}

.transition-overlay {
	background-color: black;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	transition: opacity 1s ease;
	z-index: 9996;
	pointer-events: none;
	opacity: 0;
}

.transition-overlay.visible {
	opacity: 1;
	z-index: 9996;
}

.auto-margin {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.coinbaseButton {
	border-radius: 0.5rem;
}

button {
	margin-top: 1em;
	margin-bottom: 1em;
	background-color: rgba(49, 49, 49, 0.54);
	color: white;
	outline-color: white;
	border: 1px solid white;
	padding: 0.5rem;
}
button:hover {
	background-color: white;
	color: black;
}
button:active {
	background-color: black;
	color: white;
}

.buttonGrey {
	margin-top: 1em;
	margin-bottom: 1em;
	background-color: rgba(56, 56, 56, 1);
	box-shadow: 0 0 0 0.1rem rgba(0, 0, 0, 1) !important;
	padding: 0.5rem;
	color: white;
	outline-color: rgb(0, 0, 0);
	/* border: 1px solid rgb(0, 0, 0); */
	border: none;
	border-radius: 12px;
	/* width: 204.75px; */
	width: 12.5rem;
}
.buttonGrey:hover {
	background-color: rgb(64, 64, 64);
}
.buttonGrey:active {
	background-color: rgb(77, 77, 77);
	color: white;
}

input {
	width: 100%;
}

.flexCenter {
	display: flex;
	justify-content: center;
	align-items: center;
}
.walletConnect {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	align-content: center;
}
.centeredMargin50 {
	display: block;
	margin-left: auto;
	margin-right: auto;
}
.hovering-claim-button {
	position: fixed;
	bottom: 0;
	right: 0;
	z-index: 1000;
	padding: 10px;
}

.hovering-claim-button #m-connection {
	width: auto !important;
	margin: 2rem;
	/* drop shadow*/
	box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.9);
}

.hovering-claim-button #m-connection button {
	background: #620000e8 !important;
}
/*-----*/

/* nav bar */

.navbar.fade-background {
	background-color: rgba(0, 0, 0, 1) !important;
	/*    transition: background-color 1s ease;*/
}

.navbar.fade-background .dropdown-menu {
	background-color: rgb(0, 0, 0) !important;
	/*    transition: background-color 1s ease;*/
}

.transparent-dropdown.fade-background {
	background-color: rgba(0, 0, 0, 1) !important;
	/*    transition: background-color 1s ease;*/
}

.dropdown-menu-scrolled {
	background-color: rgb(0, 0, 0) !important;
	/*    transition: background-color 1s ease;*/
}

.logo-image {
	height: 2rem;
	width: 11.2rem;
}
.owl-article {
	height: auto;
	width: 30%;
	margin-left: auto;
	margin-right: auto;
	display: block;
	margin-top: 3rem;
}
.auto-margin {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.text-centered {
	text-align: center !important;
}
.outlinedBox {
	border: 1px solid white;
	padding: 1rem;
	margin: 1rem;
}
.outlinedBox form {
	padding-top: 1rem !important;
}
.outlinedBox form button {
	margin-top: 2rem !important;
}
.theme-icon-link {
	margin-left: 7rem;
}
.theme-icon {
	height: 1rem;
	width: 1rem;
}

.nav-link-spacing {
	margin-right: 1rem;
}

.navbar {
	background-color: transparent !important;
	padding-bottom: 0.1rem;
	/* transition: background-color 1s ease; */
}
.navbar .logo-image {
	margin-right: 2rem;
}

.navbar .logo-image {
	margin-left: 2rem;
}
.navbar .navbar-brand {
	color: white !important;
}

.navbar .nav-link {
	color: white !important;
	margin-left: 2rem;
}

.transparent-dropdown {
	background-color: transparent !important;
	border-color: transparent !important;
}

.transparent-dropdown .dropdown-item.active {
	background-color: transparent !important;
}

.dropdown-menu {
	background-color: transparent;
	border-color: transparent;
	/* transition: background-color 1s ease !important;  */
	border-radius: 0%;
}

.transparent-dropdown .dropdown-item {
	margin-left: 1.5rem;
	font-size: 1.01rem;
	color: white !important;
	border-color: transparent !important;
}

.transparent-dropdown .dropdown-item:hover {
	background-color: rgba(255, 255, 255, 0.1) !important;
}

.navbar-toggler {
	border-color: transparent !important;
}

.navbar-toggler span {
	display: none;
	border-color: transparent !important;
}

.navbar-toggler:focus {
	border-color: transparent !important;
	box-shadow: none !important;
}
.navbar-toggler:after {
	content: "Menu";
	display: inline-block;
	color: white !important;
	padding-right: 2em;
	border-color: transparent !important;
}

.navbar-collapse.collapsing {
	text-align: right;
	padding-right: 2em;
}
.navbar-collapse.collapse.show {
	text-align: right;
	padding-right: 2em;
}

.navbar-collapse.collapse.show .dropdown-menu {
	text-align: right;
	padding-right: 1em;
}

@media (min-width: 992px) {
	.navbar-collapse.collapsing {
		text-align: left;
		padding-right: 0;
	}

	.navbar-collapse.collapse.show {
		text-align: left;
		padding-right: 0;
	}

	.navbar-collapse.collapse.show .dropdown-menu {
		text-align: left;
		padding-right: 0;
	}
}

/*-----*/
.inputForm {
	padding-top: 2em;
}
/*-----*/
.spacerShort {
	height: 10vh;
}
.spacer {
	height: 60vh;
}
/*-----*/
.archiveQuarter {
	margin-top: 2rem;
	margin-bottom: 2%;
	padding: 0 !important;
}
.marketplace-container {
	margin-top: 1rem;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}
.marketplace-container .marketplace-logo {
	width: 2rem;
	height: 2rem;
	margin: 1rem;
}
/* Wallet Connect */

.campaign-buy-button::before {
	font-family: "Source Code Pro", monospace;
	font-size: 1rem;
}
/* this is only active for 0.01E mints */
/* otherwise Manifold controls the button */
.token-card-mint .campaign-buy-button {
	font-size: 0 !important; /* Hide text */
	position: relative;
}

.token-card-mint .campaign-buy-button::before {
	content: "Mint 0.01E";
}

.frame-container {
	width: var(--claimTokenWidth) !important;
	margin: 0 !important;
}

.m-checkout-tweet {
	display: none !important;
}
.checkout-tweet {
	display: none !important;
}
.m-checkout-subscribe {
	display: none !important;
}
.checkout-subscribe {
	display: none !important;
}
.m-checkout-success-actions {
	display: block !important;
}
.checkout-success-actions {
	display: block !important;
}

.m-project {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}
#m-connection {
	width: 20rem;
}
#m-connect {
}

.m-connection-wallet-logo {
	padding: 0 !important;
}
/* Styles all the internal buttons */
#m-connection button {
	display: flex;
	position: relative;
	flex-direction: column;
	align-items: center;
	justify-items: center;
	justify-content: center;
	padding: 0.75rem;
	/* margin: 1rem; */
	color: white;
	font-family: "Source Code Pro", monospace;
	text-transform: uppercase;
	font-size: auto;
	line-height: 1.3rem;
	height: 60px !important;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);

	/* width: 15rem; */
	/* background: #0000004d !important; */
	transition: color 0.3s ease, border 0.3s ease, background 0.3s ease;
}

/* The hover effect for the buttons */
#m-connection button:hover {
	background: white;
	color: black;
}

/* The layout of the wallet options drop down */
#m-connection .m-connection-wallet-options {
	gap: 1rem;
	display: flex;
	flex-direction: column;
}
/* The disconnect button */
#m-connection .m-connection-disconnect-wallet {
	margin: 0;
}

/* Styles the placeholder div while the JS loads */
#m-connect:not([data-v-app]) {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-items: center;
	justify-content: center;
	padding: 1rem;
	height: 3rem;
	width: auto;
	background: transparent;
	border: 2px solid white;
	border-radius: 51px;
	transition: color 0.3s ease, border 0.3s ease, background 0.3s ease;
}

/* Styles the placeholder div while the JS loads */
#m-connect:not([data-v-app])::before {
	content: "Loading...";
	color: white;
	font-family: "Apercu", sans-serif;
	font-size: 16px;
	text-transform: uppercase;
}
/*-----*/
/* Portal */
.intranet {
}
.captchaImg {
	width: 90%;
	height: auto;
	margin: 1rem;
}
.onboarding input {
	width: 90%;
}
.onboarding button {
	margin: 1rem;
}
.onboarding .m-connection-connect-wallet {
	margin: 0;
}
.onboarding .campaign-buy-button {
	margin: 0;
}
.onboarding input {
	margin: 1em;
}
.captchaContainer button {
	margin-right: 2em;
}

.error-message {
	color: red;
}
/* Fade in from 0 to 1 opacity */
.fade-in {
	opacity: 1;
	transition: opacity 0.5s ease-in;
}

/* Fade out from 1 to 0 opacity */
.fade-out {
	opacity: 0;
	transition: opacity 0.5s ease-out;
}

.intranet h1 {
	padding: 0 !important;
}
.intranetContainer {
	border: white;
	border-style: solid;
}
.portalLogo {
	width: 40rem;
	padding: 1rem;
}
.intranet .App {
	justify-content: left !important;
	display: flex;
}
.intranet #m-connect {
	justify-content: left !important;
}

.portalLoginWrapper {
	display: flex;
	justify-content: left;
	margin-top: 1rem;
}
.portalLoginDropdown {
	width: 20rem;
}
.portalLoginStatus {
	text-align: center;
	margin-top: 1rem;
	margin-bottom: 2rem;
}
.PortalFooter {
	padding-top: 5rem;
}

.PortalNav {
	text-align: center;
	display: flex;
}
.PortalNav button {
	flex-grow: 1;
	text-align: center; /* optional, to center the text inside buttons */
}

.news-links {
	margin-top: 1rem;
}
/* Style for the div containing each link */
.news-link-container {
	margin-bottom: 2rem; /* Adds some space below each link container */
}
/* intranet resources*/
.resource-header {
	font-size: 1.2rem;
	font-weight: bold;
	display: flex;
	justify-content: space-between;
}

.resource-basic {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.resource-header span,
.resource-basic span {
	text-align: left;
	box-sizing: border-box;
}

/* Explicitly setting the width for each column */
.resource-header span:nth-child(1),
.resource-basic span:nth-child(1) {
	flex: 3;
}

.resource-header span:nth-child(2),
.resource-basic span:nth-child(2) {
	flex: 2; /* Adjust this value as needed */
}

.resource-header span:nth-child(3),
.resource-basic span:nth-child(3) {
	flex: 1; /* Adjust this value as needed */
}

.resource-row {
	border-bottom: 1px solid #ccc;
	cursor: pointer;
	flex-direction: column;
	align-items: start;
	padding: 10px;
	transition: background-color 0.3s ease-in-out;
}

.resource-extra {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.3s ease-in-out;
	padding: 0 10px;
}

.resource-row:hover {
	background-color: #1d1d1d;
}

.resource-row:hover .resource-extra {
	max-height: 100px;
}
/* vonGoomPaper.css */
.latex-table {
	width: 100%;
	border-collapse: collapse;
	text-align: left;
	font-size: 0.7em;
}

.latex-table th,
.latex-table td {
	border-bottom: 1px solid black;
	padding: 8px;
}

.latex-table thead th {
	border-top: 2px solid black;
}

.latex-table .section td {
	border-top: 1px solid black;
}

.lostFound {
	background-color: white;
	color: black;
	padding: 20px;
	width: 99vw;
	padding: 6em 1em 1em;
	font-family: Arial, Helvetica, sans-serif;
}

.lostFound a {
	color: blue;
	text-decoration: underline;
}
body.dark .lostFound a {
	color: blue;
}
.lostFound .animatedText {
	/* Your styling here */
	font-weight: bold;
	animation: lost-blink-animation 1s steps(5, start) infinite;
}

@keyframes lost-blink-animation {
	to {
		visibility: hidden;
	}
}

.lostFound .pagination {
	display: flex;
	align-items: center;
	gap: 10px; /* Adjust the gap as needed */
}

.lostFound .pagination a {
	color: blue;
	text-decoration: none;
	padding: 5px; /* Adds some padding around the links */
}

.lostFound .pagination a.active {
	color: black;
	text-decoration: none;
}

.lostFound .pination span {
	white-space: nowrap; /* Keeps the ellipsis or text together */
}

.vonGoomPaper {
	background-color: white;
	color: black;
	padding: 20px;
	width: 99vw;
	padding: 6em 1em 1em;
	font-family: Arial, Helvetica, sans-serif;
}
.vonGoomPaperBody {
	margin: 5vw;
	margin-left: auto;
	margin-right: auto;
	max-width: var(--maxWidth);
}
.vonGoomSubtext {
	font-size: 0.8em;
}

.vonGoomPaper a {
	color: blue;
	text-decoration: underline;
}
body.dark .vonGoomPaper a {
	color: blue;
}

/* brain worm simulator*/
@keyframes fadeSimulatorButtons {
	0% {
		filter: brightness(100%) contrast(100%);
	}
	50% {
		filter: brightness(140%) contrast(140%);
	}
	100% {
		filter: brightness(100%) contrast(100%);
	}
}
.fade-simulator-buttons {
	animation: fadeButtons 2s infinite;
}
@keyframes fadeSimulatorUserManualButtons {
	0% {
		filter: brightness(100%) contrast(100%);
	}
	50% {
		filter: brightness(200%) contrast(150%) saturate(200%);
	}
	100% {
		filter: brightness(100%) contrast(100%);
	}
}
.fade-simulator-usermanual-buttons {
	animation: fadeButtons 5s infinite;
}

.clickable {
	cursor: pointer !important;
}
.grab {
	cursor: -webkit-grab;
	cursor: grab;
}
/* BrainWormDoctor.css */
.BrainWormDoctor {
	background-color: white;
	color: black;
	padding: 20px;
	height: 150vh;
	padding: 3em 1em 1em;
	width: 80vw;
	/* give the whole div a thick blue border*/
	border: 40px solid var(--brainWormDoctorColor1);
}

.BrainWormDoctor .dashboard .disclosure-card {
	color: var(--brainWormDoctorColor2);
	background: white;
}

.BrainWormDoctor .dashboard .disclosure-card:hover {
	color: var(--brainWormDoctorColor1);
}

.BrainWormDoctor h3 {
	color: var(--brainWormDoctorColor1);
}
.BrainWormDoctor h4 {
	color: var(--brainWormDoctorColor1);
}
.BrainWormDoctor .logo {
	display: block;
	margin: auto;
	max-width: 10em;
	height: 3em;
}

.BrainWormDoctor .report-container {
	max-width: 600px;
	margin: auto;
	padding: 10px;
}

.BrainWormDoctor .ContactAdminForm {
	display: flex;
	flex-direction: column;
	gap: 10px;
	max-width: var(--maxWidth);
}
.BrainWormDoctor .ContactAdminForm h4 {
	color: var(--brainWormDoctorColor1);
}

.BrainWormDoctor .form {
	max-width: var(--maxWidth);
}

.BrainWormDoctor .ContactAdminTextarea {
	width: calc(100% - 20px);
	height: 300px;
	padding: 8px 10px;
	margin-bottom: 10px;
	border: 1px solid var(--brainWormDoctorColor2);
	resize: none; /* Optional: Disables the user's ability to resize the textarea */
}

.BrainWormDoctor .inputForm {
	max-width: var(--maxWidth);
	margin: auto;
}

.BrainWormDoctor .inputForm label {
	display: block;
	margin-top: 10px;
}

.BrainWormDoctor .inputForm input {
	width: calc(100% - 20px);
	padding: 8px 10px;
	margin-bottom: 10px;
	border: 1px solid var(--brainWormDoctorColor2);
}

.BrainWormDoctor .submit-button {
	padding: 10px 20px;
	border: none;
	background-color: var(--brainWormDoctorColor1);
	color: white;
	cursor: pointer;
	border-radius: 5px;
}

.BrainWormDoctor .submit-button:hover {
	background-color: var(--brainWormDoctorColor2);
}

.BrainWormDoctor .inputForm button {
	padding: 10px 20px;
	border: none;
	background-color: var(--brainWormDoctorColor1);
	color: white;
	cursor: pointer;
	border-radius: 5px;
}

.BrainWormDoctor .inputForm button:hover {
	background-color: var(--brainWormDoctorColor2);
}

.BrainWormDoctor .success-message {
	text-align: center;
	padding: 10px;
	background-color: #f0f0f0;
	border-radius: 5px;
	font-size: 0.8em;
}

.BrainWormDoctor .success-message h2 {
	color: var(--brainWormDoctorColor1);
}

.BrainWormDoctor .success-message p {
	margin: 0;
}

.BrainWormDoctor .serverMessage {
	margin-top: 10px;
}

.BrainWormDoctor .dashboard {
	max-width: 600px;
	margin: auto;
	padding: 20px;
}

.BrainWormDoctor .dashboard h4 {
	color: var(--brainWormDoctorColor1);
}

/* BrainWormDoctor foot*/
.BrainWormDoctor-footer {
	padding: 1em;
	font-size: 0.5em;
	/* border-top: 1px solid var(--brainWormDoctorColor1); */
}

.BrainWormDoctor-footer a {
	color: var(--brainWormDoctorColor1);
}

/* BrainWormDoctor nav*/
.BrainWormDoctor-nav {
	display: flex;
	align-items: center;
	justify-content: space-between;
	/* padding: 1em 2em; */
	background-color: white;
	height: 3em;
	/* border-bottom: 1px solid var(--brainWormDoctorColor1); */
}

.BrainWormDoctor-nav nav ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
	display: flex;
}

.BrainWormDoctor-nav nav ul li {
	margin: 0 2em;
	font-weight: bold;
}

.BrainWormDoctor-nav nav ul li a {
	text-decoration: none;
	color: var(--brainWormDoctorColor1);
	transition: color 0.3s;
}

.BrainWormDoctor-nav nav ul li a:hover,
.BrainWormDoctor-nav nav ul li a.active {
	color: var(--brainWormDoctorColor2);
}

.BrainWormDoctor-nav .user-info {
	margin-left: auto;
	padding: 1em;
	color: var(--brainWormDoctorColor1);
	font-size: 0.8em;
}

@media (max-width: 75em) {
	.BrainWormDoctor-nav nav ul li {
		margin: 0 1em;
		font-size: 0.9em;
	}

	.BrainWormDoctor-nav .user-info {
		padding: 1em 0.5em;
		font-size: 0.7em;
	}
}

@media (max-width: 40em) {
	.BrainWormDoctor-nav nav ul li {
		margin: 0 0.7em;
		font-size: 0.65em;
	}

	.BrainWormDoctor-nav .user-info {
		padding: 1em 0.4em;
		font-size: 0.5em;
	}
}

/* PurpleSmoke.css */
.purpleSmoke {
	background-color: white;
	color: black;
	padding: 20px;
	width: 95vw;
	height: 100vh;
	padding: 6em 1em 1em;
}

.purpleSmoke .logo {
	display: block;
	margin: auto;
	width: 30%;
	max-width: 10em;
}

.purpleSmoke .report-container {
	max-width: 700px;
	margin: auto;
	padding: 20px;
}

.purpleSmoke .violationForm {
	display: flex;
	flex-direction: column;
	gap: 20px;
	max-width: var(--maxWidth);
}
.purpleSmoke .form {
	max-width: var(--maxWidth);
}

.purpleSmoke .violationTextarea {
	width: calc(100% - 20px);
	height: 200px;
	padding: 8px 10px;
	margin-bottom: 10px;
	border: 1px solid purple;
	resize: none; /* Optional: Disables the user's ability to resize the textarea */
}

.purpleSmoke .inputForm {
	max-width: var(--maxWidth);
	margin: auto;
}

.purpleSmoke .inputForm label {
	display: block;
	margin-top: 10px;
}

.purpleSmoke .inputForm input {
	width: calc(100% - 20px);
	padding: 8px 10px;
	margin-bottom: 10px;
	border: 1px solid purple;
}

.purpleSmoke .submit-button {
	padding: 10px 20px;
	border: none;
	background-color: purple;
	color: white;
	cursor: pointer;
	border-radius: 5px;
}

.purpleSmoke .submit-button:hover {
	background-color: darkmagenta;
}

.purpleSmoke .success-message {
	text-align: center;
	padding: 20px;
	background-color: #f0f0f0;
	border-radius: 5px;
}

.purpleSmoke .success-message h2 {
	color: purple;
}

.purpleSmoke .success-message p {
	margin: 0;
}

.purpleSmoke .serverMessage {
	margin-top: 10px;
}

.purpleSmoke .dashboard {
	max-width: 800px;
	margin: auto;
	padding: 20px;
}

.disclosure-cards {
	display: flex;
	flex-wrap: wrap;
	gap: 2em;
}

.disclosure-card {
	flex: 1 1 calc(33.333% - 20px);
	background: #f0f0f0;
	border-radius: 5px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
	padding: 20px;
	transition: box-shadow 0.3s;
}

.disclosure-card:hover {
	box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

/* Responsive design */
@media (max-width: 768px) {
	.disclosure-card {
		flex: 1 1 calc(50% - 20px);
	}
}

@media (max-width: 480px) {
	.disclosure-card {
		flex: 1 1 calc(100% - 20px);
	}
}

/* purple foot*/
.purpleSmoke-footer {
	padding: 1em;
	border-top: 1px solid purple;
}

/* purple nav*/
.purpleSmoke-nav {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1em 2em;
	background-color: white;
	border-bottom: 1px solid purple;
}

.purpleSmoke-nav nav ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
	display: flex;
}

.purpleSmoke-nav nav ul li {
	margin: 0 2em;
	font-weight: bold;
}

.purpleSmoke-nav nav ul li a {
	text-decoration: none;
	color: purple;
	transition: color 0.3s;
}

.purpleSmoke-nav nav ul li a:hover,
.purpleSmoke-nav nav ul li a.active {
	color: darkmagenta;
}

.purpleSmoke-nav .user-info {
	margin-left: auto;
	padding: 1em;
	color: purple;
	font-size: 0.8em;
}

@media (max-width: 75em) {
	.purpleSmoke-nav nav ul li {
		margin: 0 1em;
		font-size: 0.9em;
	}

	.purpleSmoke-nav .user-info {
		padding: 1em 0.5em;
		font-size: 0.7em;
	}
}

@media (max-width: 40em) {
	.purpleSmoke-nav nav ul li {
		margin: 0 0.7em;
		font-size: 0.65em;
	}

	.purpleSmoke-nav .user-info {
		padding: 1em 0.4em;
		font-size: 0.5em;
	}
}
/* visitor pass */
/* Adjust the width as per your requirements */
.visitor-form {
	max-width: var(--maxWidth);
}
.visitor-form .form-control {
	width: 100%;
	max-width: var(--maxWidth);
}
.form-control-date {
	width: 19rem;
	max-width: var(--maxWidth);
}

/* If you want to style the button as well */
.visitor-form button {
	max-width: var(--maxWidth);
	padding: 1rem;
}

/* If you want to set the same width for labels as well */
.visitor-form label {
	display: block;
	margin-top: 1.2rem;
	margin-bottom: 0.4rem;
}
.visitor-form textarea {
	width: var(--maxWidth);
	resize: none; /* Optional: Disables the user's ability to resize the textarea */
}

/*-----*/

.blueDragonRecords {
	background-color: rgb(0, 0, 0);
	font-family: "Courier New", Courier, monospace;
	padding-top: 5em;
	width: 50vw;
	height: 100vh;
	font-size: 1em;
}

.blueDragonRecords a {
	color: rgb(255, 0, 0);
	font-size: 1em;
}

.blueDragonRecords-error-text {
	font-size: 9px;
	color: rgb(255, 0, 0);
	font-size: 1em;
}

.blueDragonRecords-button {
	margin-top: 50px;
	font-size: 20px;
	color: white;
	border-color: white;
	cursor: not-allowed; /* make the cursor indicate the button is disabled, though it's not */
}

/* lost and founds */

.LlostAndFound {
	background-color: rgb(0, 0, 255);
	font-family: "Courier New", Courier, monospace;
	padding-top: 5em;
	width: 100vw;
	height: 100vh;
	font-size: 0.5em;
}

.LlostAndFound a {
	color: white;
	font-size: 1em;
}

.LlostAndFound-error-text {
	font-size: 9px;
	color: white;
	font-size: 1em;
}

.LlostAndFound-button {
	margin-top: 50px;
	font-size: 20px;
	color: white;
	border-color: white;
	cursor: not-allowed; /* make the cursor indicate the button is disabled, though it's not */
}

/* syslogrequestline */

.syslogrequestline {
	background-color: rgb(255, 0, 0);
	font-family: "Courier New", Courier, monospace;
	padding-top: 5em;
	width: 100vw;
	height: 100vh;
	font-size: 4em;
}

.syslogrequestline a {
	color: white;
	font-size: 4em;
}

.syslogrequestline-error-text {
	font-size: 24px;
	color: white;
	font-size: 4em;
}

.syslogrequestline-button {
	margin-top: 50px;
	font-size: 20px;
	color: white;
	border-color: white;
	cursor: not-allowed; /* make the cursor indicate the button is disabled, though it's not */
}
/* contact xio sky error page */

.contactXioSkyPage {
	background-color: rgb(124, 124, 124);
	font-family: "Courier New", Courier, monospace;
	padding: 5em 1em 1em 1em;
	min-width: 100vw;
	min-height: 100vh;
	font-size: 0.9em;
}

.contactXioSkyPage p {
	max-width: 90vw;
}
.contactXioSkyPage a {
	color: white;
	font-size: 0.9em;
}

.contactXioSkyPage-error-text {
	font-size: 0.9em;
	color: white;
}

.contactXioSkyPage-button {
	margin-top: 40px;
	font-size: 0.9em;
	color: white;
	border-color: white;
	cursor: not-allowed; /* make the cursor indicate the button is disabled, though it's not */
}

.input-container {
	position: relative;
	display: inline-block;
}

.transparent-input {
	background: transparent;
	border: none;
	outline: none;
	color: rgba(255, 255, 255, 0.7);
	font-weight: bold;
	width: auto;
	border-bottom: 1px solid #ffffff;
}

.transparent-input:focus {
	outline: none;
	border-bottom: 1px solid #ffffff;
}

.transparent-input::placeholder {
	color: rgba(183, 183, 183, 0.5);
}

.blinking-cursor {
	position: absolute;
	top: 0;
	left: 0;
	height: 1em;
	width: 0.5em;
	background-color: #ffffff;
	animation: blink 1s step-end infinite;
}

@keyframes blink {
	0%,
	100% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
}

/*-----*/
.footer-container {
	padding: 1rem;
}

/*-----*/
/*token cards*/
.token-card {
	width: 100%;
	padding: 0.5rem;
	padding-bottom: 1rem;
	box-sizing: border-box;
	align-items: center;
	align-content: center;
	display: flex;
	justify-content: flex-end;
	flex-wrap: wrap;
	flex-direction: column;
	/* text-align: center; */
}
.token-container-single .token-card {
	text-align: center;
}
.token-card img {
	width: var(--claimTokenWidth);
	height: auto;
}

.token-container-single .token-card img {
	width: 75%;
	height: auto;
}
/* same for mint card this could be reafactored*/
.token-card-mint {
	width: 100%;
	padding: 0.5rem;
	padding-bottom: 4rem;
	box-sizing: border-box;
	align-items: center;
	align-content: center;
	display: flex;
	justify-content: flex-end;
	flex-wrap: wrap;
	flex-direction: column;
	/* text-align: center; */
}
.token-container-single .token-card-mint {
	text-align: center;
}
.token-card-mint img {
	width: var(--claimTokenWidth);
	height: auto;
	padding-bottom: 1rem;
}

.token-container-single .token-card-mint img {
	width: 75%;
	height: auto;
}

/* grid cards */
.grid-card {
	width: 100%;
	padding: 2rem;
	box-sizing: border-box;
}
.grid-card a {
	display: block;
	text-decoration: none;
	color: inherit;
}
.grid-card img {
	width: 100%;
	height: auto;
	padding-bottom: 1rem;
}
.grid-card .caption {
	padding-top: 0.5rem;
}

.article-container .grid-container-wider {
	padding-left: 1rem;
	padding-right: 1rem;
	grid-template-columns: repeat(1, 1fr);
}
.article-container .grid-container-wider .grid-card {
	padding: 0.25rem !important;
	width: 100%;
}

.article-container .grid-container {
	padding: 1rem;
	grid-template-columns: repeat(1, 1fr);
}
.article-container .grid-container .grid-card {
	padding: 0.25rem !important;
	width: 75%;
}
.article-container .grid-container h2 {
	padding: 0px;
}

/*-----*/
/* text cards */

.homePageCard .text-card {
	padding-left: 2rem !important;
}

.text-card {
	width: 100%;
	padding: 1rem;
	box-sizing: border-box;
}
.text-card h2 {
	padding: initial !important;
}
.text-card a {
	display: block;
	text-decoration: none;
	color: inherit;
}
.text-card .text-content {
	padding-top: 0.5rem;
}
.text-card-caption {
	font-size: 0.9rem;
}
@media (min-width: 768px) {
	.grid-container {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 1rem;
	}
	.token-container {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 1rem;
	}
	.token-container-single {
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		gap: 0rem;
	}

	.text-container {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.text-card {
		flex-basis: calc(100% / 3 - 1rem);
	}
}

@media (min-width: 992px) {
	.grid-container {
		grid-template-columns: repeat(3, 1fr);
	}
	.token-container {
		grid-template-columns: repeat(2, 1fr);
	}
	.token-container-single {
		grid-template-columns: repeat(1, 1fr);
	}
}

/*-----*/

/*video*/
.video {
	position: fixed;
	width: 100%;
	height: 100%;
	object-fit: cover;
	z-index: -1;
}

.bg-video-container {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-size: cover;
	background-position: center;
	transition: background-image 0.1s ease;
	z-index: -3;
}

.video-container {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-size: cover;
	background-position: center;
	transition: background-image 0.1s ease;
	z-index: -2;
}

.video-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	transition: background-image 0.5s ease; /* Add a transition effect for smooth animation */
	background-size: cover;
	background-position: center;
	background-color: rgba(0, 0, 0, 0.4); /* Adjust transparency as needed */
	pointer-events: none; /* Allows interaction with elements beneath the overlay */
	z-index: 0;
}
/*-----*/

/* site map */
.sitemap {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.sitemap div {
	flex: 1;
}

.sitemap ul {
	list-style: none;
	padding: 0;
}

.sitemap h2 {
	margin-bottom: 15px;
}

/*-----*/

.content-container {
	display: grid;
	place-items: center;
	/* max-width: 90%;
	margin-left: auto;
	margin-right: auto; */
}
.main-content {
	position: relative;
	z-index: 1;
	padding: 0; /* Remove padding */
}

/* Add padding to the elements inside main-content that need it */
.mainHeading,
.article-container {
	padding: 1rem;
	max-width: var(--maxWidth);
}

.article-container h1 {
	padding-top: 5rem;
	padding-bottom: 3rem;
}
.article-container h2 {
	padding-top: 3rem;
	padding-bottom: 2rem;
}
.article {
	padding: 1rem;
}
.article img {
	max-width: var(--maxWidth);
	padding-bottom: 1rem;
}
.article li {
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.full-image {
	display: flex;
	justify-content: center;
	width: 100vw;
	position: relative;
	left: 50%;
	right: 50%;
	margin-left: -50vw;
	margin-right: -50vw;
	overflow: hidden;
}

.article-image {
	flex: 0 0 auto; /* This prevents the image from growing or shrinking */
	width: 100%;
	max-width: 100% !important;
	height: auto;
	object-fit: cover;
}
.image-container {
	margin-top: 2rem;
	margin-bottom: 2rem;
}

@media (max-width: 992px) {
	.article img {
		max-width: 70%;
	}
}
.title-container {
	border-top: 1px solid white;
	padding: 2rem;
}

.grid-container h2 {
	font-size: 1.2rem;
}

.text-container h2 {
	font-size: 1.5rem;
	font-weight: bold;
}

/* title slogan heading on home page*/
.mainHeading {
	font-size: 1.4rem !important;
	text-align: left;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	color: white;
	max-width: 45em; /* Adjust this value based on your preference */
}
.mainHeading h1 {
	/* font-size:4rem !important;     */
	/* instead calculate it based on the width of the screen */
	font-size: calc(1.5rem + 2vw);
}
.mainHeading p {
	font-size: calc(0.8rem + 0.5vw);
}

.solid-container {
	background-color: black;
	color: white;
	width: 100%;
}

/*-----*/
/* COLOR INVERSION THEME */

body.dark {
	color: black;
	background-color: white;
}

body.dark .navbar.fade-background {
	background-color: rgb(255, 255, 255) !important;
	/*    transition: background-color 1s ease;*/
}

body.dark a {
	color: black;
}

body.dark button {
	background-color: transparent;
	color: black;
	outline-color: black;
	border: 1px solid black;
}
body.dark button:hover {
	background-color: black;
	color: white;
}
body.dark button:active {
	background-color: white;
	color: black;
}

body.dark .buttonGrey {
	background-color: rgb(247, 247, 247);
	color: black;
	outline-color: black;
	border: 1px solid black;
	box-shadow: 0 0 0 0.1rem rgba(0, 0, 0, 1) !important;
}
body.dark .buttonGrey:hover {
	/* background-color: black;
	color: white; */
}
body.dark .buttonGrey:active {
	background-color: white;
	color: black;
}

body.dark .outlinedBox {
	border: 1px solid black;
}
body.dark .transition-overlay {
	background-color: white;
	/*    transition: background-color 1s ease;*/
}

body.dark .navbar.fade-background {
	background-color: rgb(255, 255, 255) !important;
	/*    transition: background-color 1s ease;*/
}

body.dark .navbar.fade-background .dropdown-menu {
	background-color: rgb(255, 255, 255) !important;
	/*    transition: background-color 1s ease;*/
}

body.dark .transparent-dropdown.fade-background {
	background-color: rgb(255, 255, 255) !important;
	/*    transition: background-color 1s ease;*/
}

body.dark .dropdown-menu-scrolled {
	background-color: rgb(255, 255, 255) !important;
	/*    transition: background-color 1s ease;*/
}

body.dark .navbar .navbar-brand {
	color: rgb(0, 0, 0) !important;
}

body.dark .navbar .nav-link {
	color: rgb(0, 0, 0) !important;
}

body.dark .transparent-dropdown .dropdown-item {
	color: rgb(0, 0, 0) !important;
}
body.dark .transparent-dropdown .dropdown-item:hover {
	background-color: rgba(0, 0, 0, 0.1) !important;
}
body.dark .navbar-toggler:after {
	content: "Menu";
	display: inline-block;
	color: rgb(0, 0, 0) !important;
	padding-right: 2em;
	border-color: transparent !important;
}
body.dark .title-container {
	border-top: 1px solid rgb(0, 0, 0);
}
body.dark .mainHeading {
	color: rgb(0, 0, 0) !important;
}
body.dark .solid-container {
	background-color: rgb(255, 255, 255, 0.95);
	color: rgb(0, 0, 0);
}

body.dark .article-container {
	background: linear-gradient(
		to bottom,
		rgba(255, 255, 255, 0.95) 0%,
		rgba(255, 255, 255, 0.95) 15%,
		rgba(255, 255, 255, 0.95) 85%,
		rgba(255, 255, 255, 0.95) 100%
	);
	color: rgb(0, 0, 0);
	/* backdrop-filter: blur(6px); */
}

body.dark .hovering-claim-button #m-connection button {
	background: #ee4747e8 !important;
}

body.dark .text-card {
	background-color: rgba(255, 255, 255, 0.9);
}
body.dark .mainHeading {
	background-color: rgba(255, 255, 255, 0.95);
}

/* .text-card{
    background-color:rgb(0, 0, 0);
}
.mainHeading{
    background-color:rgb(0, 0, 0);
}
.article-container{
    background-color:rgb(0, 0, 0);
} */

body.dark .video-overlay {
	background: linear-gradient(
		to bottom,
		rgba(255, 255, 255, 0.2) 0%,
		rgba(255, 255, 255, 0.2) 15%,
		rgba(255, 255, 255, 0.2) 90%,
		rgba(255, 255, 255, 0.2) 100%
	);
	/* backdrop-filter: blur(6px); */
}

body.dark .intranetContainer {
	border: rgb(0, 0, 0);
	border-style: solid;
}
/* resource cards*/
body.dark .resource-row:hover {
	background-color: rgba(
		0,
		0,
		0,
		0.1
	); /* You can choose your own hover color */
}
body.dark .resource-row {
	border-bottom: 1px solid rgb(50, 50, 50);
}
body.dark .resource-row:hover {
	background-color: rgba(152, 152, 152, 0.1);
}

/* dark manifold connection*/
body.dark #m-connection button {
	color: black;
	border-color: black;
}
body.dark #m-connection button:hover {
	background-color: white;
	color: black;
}
body.dark #m-connection button:active {
	background-color: black;
	color: white;
}
body.dark #m-connection .m-connection-wallet-options {
	background-color: rgb(255, 255, 255);
}
body.dark #m-connection .m-connection-disconnect-wallet {
	color: rgb(255, 255, 255);
}
body.dark #m-connection .m-connection-disconnect-wallet:hover {
	background-color: rgba(0, 0, 0, 0.1);
}
body.dark #m-connect:not([data-v-app]) {
	background-color: rgb(255, 255, 255);
	border-color: rgb(0, 0, 0);
}
body.dark #m-connect:not([data-v-app])::before {
	color: rgb(0, 0, 0);
}

/* dark purple smoke*/
body.dark .purpleSmoke {
	background-color: black;
	color: white;
}

body.dark .purpleSmoke .inputForm input {
	background-color: #222;
	color: white;
	border: 1px solid #555;
}

body.dark .purpleSmoke .submit-button {
	background-color: #555;
	color: white;
}
body.dark .disclosure-card {
	background-color: #222;
	color: white;
	border: 1px solid #555;
}
.disclosure-card:hover {
	box-shadow: 0 4px 15px rgba(255, 255, 255, 0.2);
}

body.dark .purpleSmoke .submit-button:hover {
	background-color: #333;
}
body.dark .purpleSmoke-nav {
	background-color: rgb(0, 0, 0);
}

/*-----*/

/* FILE SYS */
.filesys {
	z-index: 9999;
	position: fixed;
	box-sizing: border-box;
	inset: 0px;
	width: 100vw;
	height: 100vh;
	font-size: large;
	padding: 2rem 2rem 4rem;
	line-height: 1.2;
	white-space: pre-wrap;
	overflow: auto;
	background-color: rgba(0, 0, 0, 0.9);
	color: white;
}
.filesys2 {
	color: rgb(232, 59, 70);
	font-size: 2em;
	white-space: pre-wrap;
	font-family: sans-serif;
	margin: 0px 2rem 2rem 0px;
	flex: 0 0 auto;
	max-height: 50%;
	overflow: auto;
}
.filesys3 {
	background-color: rgba(206, 17, 38, 0.1);
	color: rgb(252, 207, 207);
	padding: 1rem 1rem 1.5rem;
}
.filesys4 {
	color: rgb(232, 59, 70);
	font-size: 1.2em;
	margin-bottom: 1rem;
	font-family: sans-serif;
	cursor: pointer;
}
.filesys5 {
	line-height: 1.5;
	font-size: 1rem;
	font-family: Menlo, Consolas, monospace;
}
.filesysbutton {
	color: rgb(255, 255, 255);
	line-height: 1rem;
	font-size: 1.5rem;
	padding: 1rem;
	cursor: pointer;
	position: absolute;
	right: 0px;
	top: 0px;
	background-color: transparent;
	border: none;
}
